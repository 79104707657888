import { LINEFEST_ARTISTS_EXPIRATION_TS, LINEFEST_USER_ARTISTS, LineFestError, MODES, chunk, hasArtistsTSExpire } from '../utils';

import UserManager from '../user-manager.js';
import InternalDataManager from '../internal-data-manager.js';

declare const i18next: any;

export const spotifyClientId = '37bd1fb5800a486f86cbd110824e6ab7';
export const spotifyClientSecret = '6288ec5ba99148019a1fd0c3242ceb06';

/**
 * Main Spotify endpoint
 * @param accessToken
 * @returns
 */
export const processSpotifyData = async (accessToken: string) => {
    return new Promise(async (processSpotifyResolve: any, processSpotifyReject: any) => {
        try {
            let localArtists: any = localStorage.getItem(LINEFEST_USER_ARTISTS);
            if (localArtists) {
                localArtists = JSON.parse(localArtists);
            }

            let localArtistsSYTS: any = localStorage.getItem(LINEFEST_ARTISTS_EXPIRATION_TS);
            console.log(localArtists, localArtistsSYTS);

            // test timestamp localArtists
            let fetchArtists = true;
            if (localArtists && localArtistsSYTS) {
                fetchArtists = hasArtistsTSExpire();
            }

            if (UserManager.userFollowingArtists.length === 0 && fetchArtists) {
                await fetchUserFollowingArtists(UserManager.userFollowingArtists, accessToken);
                let $parentPanelLoader = null;
                if (InternalDataManager.selectedMode !== '') {
                    if (InternalDataManager.selectedMode === MODES.SELECT) {
                        $parentPanelLoader = '.select-a-festival-panel';
                    }
                    if (InternalDataManager.selectedMode === MODES.SUGGEST) {
                        $parentPanelLoader = '.suggest-a-festival-panel';
                    }
                }
                await fetchUserSavedTracks(UserManager.userFollowingArtists, accessToken);

                const $loaderMessage = document.querySelector(`${$parentPanelLoader} .loader-container .message`);

                if ($loaderMessage) {
                    $loaderMessage.innerHTML = i18next.t('fetching_artists_metadatas').replace('_nb_', UserManager.userFollowingArtists.length);
                }

                await new Promise((_func) => setTimeout(_func, 2000));
            } else if (UserManager.userFollowingArtists.length === 0 && !fetchArtists) {
                UserManager.userFollowingArtists = Object.values(localArtists);
            }

            console.log(UserManager.userFollowingArtists);

            UserManager.storeUserArtists(
                UserManager.userFollowingArtists.map((artist: any) => artist.name),
                UserManager.userFollowingArtists
            );

            UserManager.updateGenres();

            processSpotifyResolve(true);
        } catch (error) {
            console.log(error);
            processSpotifyReject(error);
        }
    });
};

/**
 * Second main endpoint
 * @param initialUserFollowingArtists
 * @param accessToken
 */
export const fetchUserFollowingArtists = async (initialUserFollowingArtists: any, accessToken: string) => {
    const apiCall = async (userFollowingArtists: any, accessToken: string, next?: string) => {
        try {
            const data = await fetchFollowing(accessToken, next);
            if (data.artists) {
                for (let i = 0; i < data.artists.items.length; i++) {
                    let artist = data.artists.items[i];
                    artist.matchingRank = 2;
                    initialUserFollowingArtists.push(artist);
                }
                if (data.artists.next) {
                    await apiCall(userFollowingArtists, accessToken, data.artists.cursors.after);
                }
            }
        } catch (error: any) {
            throw new LineFestError(error);
        }
    };

    try {
        await apiCall(initialUserFollowingArtists, accessToken);
    } catch (error: any) {
        throw new LineFestError(error);
    }
};

/**
 * Third main endpoint
 * @param initialUserFollowingArtists
 * @param accessToken
 */
export const fetchUserSavedTracks = async (initialUserFollowingArtists: any, accessToken: string) => {
    const apiCall = async (userFollowingArtists: any, accessToken: string, next?: string) => {
        try {
            const data = await fetchSavedTracks(accessToken, next);
            if (data.items) {
                let artistsLikedIds = [];
                for (let i = 0; i < data.items.length; i++) {
                    for (let j = 0; j < data.items[i].track.artists.length; j++) {
                        const likedArtist = data.items[i].track.artists[j];
                        if (!checkArtistAlreadyInFollowingArtists(initialUserFollowingArtists, likedArtist.id)) {
                            artistsLikedIds.push(likedArtist.id);
                        }
                    }
                }
                if (artistsLikedIds.length > 0) {
                    const allArtistLikedAllInfos = await fetchArtists(artistsLikedIds, accessToken);
                    for (let k = 0; k < allArtistLikedAllInfos.length; k++) {
                        let artist = allArtistLikedAllInfos[k];
                        artist.matchingRank = 1;
                        initialUserFollowingArtists.push(artist);
                    }
                }
                if (data.next) {
                    await apiCall(userFollowingArtists, accessToken, data.next);
                }
            }
        } catch (error: any) {
            throw new LineFestError(error);
        }
    };

    try {
        await apiCall(initialUserFollowingArtists, accessToken);
    } catch (error: any) {
        throw new LineFestError(error);
    }
};

const checkArtistAlreadyInFollowingArtists = (userFollowingArtists: any, artistId: string) => {
    return !!userFollowingArtists.find((userFollowingArtist: any) => userFollowingArtist.id === artistId);
};

export async function searchSpotifyArtist(artistName: string, code: string): Promise<any> {
    let url = `https://api.spotify.com/v1/search?q=` + encodeURIComponent(artistName) + '&type=artist';
    const result = await fetch(url, {
        method: 'GET',
        headers: { Authorization: `Bearer ${code}` },
    });

    try {
        if (result.status === 200) {
            const results = await result.json();
            return results;
        } else {
            if (result.body) {
                const message = await result.text();
                throw new LineFestError(message);
            }
        }
    } catch (error: any) {
        throw new LineFestError(error);
    }

    return await result.json();
}

export async function fetchFollowing(code: string, after?: string): Promise<any> {
    let url = `https://api.spotify.com/v1/me/following?type=artist&limit=50`;
    if (after) {
        url += '&after=' + after;
    }
    const result = await fetch(url, {
        method: 'GET',
        headers: { Authorization: `Bearer ${code}` },
    });

    try {
        if (result.status === 200) {
            const following = await result.json();
            return following;
        } else {
            if (result.body) {
                const message = await result.text();
                throw new LineFestError(message);
            }
        }
    } catch (error: any) {
        throw new LineFestError(error);
    }
}

export async function fetchFollowingTotal(code: string): Promise<any> {
    let url = `https://api.spotify.com/v1/me/following?type=artist&limit=50`;
    const result = await fetch(url, {
        method: 'GET',
        headers: { Authorization: `Bearer ${code}` },
    });

    try {
        if (result.status === 200) {
            const following = await result.json();
            return following;
        } else {
            if (result.body) {
                const message = await result.text();
                throw new LineFestError(message);
            }
        }
    } catch (error: any) {
        throw new LineFestError(error);
    }
}

async function fetchArtist(artistId: string, code: string): Promise<any> {
    const result = await fetch('https://api.spotify.com/v1/artists/' + artistId, {
        method: 'GET',
        headers: { Authorization: `Bearer ${code}` },
    });

    try {
        if (result.status === 200) {
            const artist = await result.json();
            return artist;
        } else {
            if (result.body) {
                const message = await result.text();
                throw new LineFestError(message);
            }
        }
    } catch (error: any) {
        throw new LineFestError(error);
    }
}

async function fetchArtists(artistIds: string[], code: string): Promise<any> {
    const SPOTIFY_API_LIMIT = 50;
    let artistsIdsChunks = chunk(artistIds, 50);
    let finalEnhancedArtists: any = [];

    const apiCall = async (artistIds: any, code: string, next?: string) => {
        const result = await fetch('https://api.spotify.com/v1/artists?ids=' + artistIds.join(','), {
            method: 'GET',
            headers: { Authorization: `Bearer ${code}` },
        });

        try {
            if (result.status === 200) {
                const artists = await result.json();
                return artists;
            } else {
                if (result.body) {
                    const message = await result.text();
                    throw new LineFestError(message);
                }
            }
        } catch (error: any) {
            throw new LineFestError(error);
        }
    };

    try {
        for (let i = 0; i < artistsIdsChunks.length; i++) {
            const artistsListAPIResult = await apiCall(artistsIdsChunks[i], code);
            if (artistsListAPIResult && artistsListAPIResult.artists) {
                finalEnhancedArtists = [...finalEnhancedArtists, ...artistsListAPIResult.artists];
            }
        }
        return finalEnhancedArtists;
    } catch (error: any) {
        throw new LineFestError(error);
    }
}

export async function fetchSavedTracks(code: string, after?: string): Promise<any> {
    let url = `https://api.spotify.com/v1/me/tracks?limit=50`;
    if (after) {
        url = after;
    }
    const result = await fetch(url, {
        method: 'GET',
        headers: { Authorization: `Bearer ${code}` },
    });

    try {
        if (result.status === 200) {
            const tracks = await result.json();
            return tracks;
        } else {
            if (result.body) {
                const message = await result.text();
                throw new LineFestError(message);
            }
        }
    } catch (error: any) {
        throw new LineFestError(error);
    }
}
