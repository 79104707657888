let I18nManagerInstance: any;

declare const i18next: any;
declare const i18nextBrowserLanguageDetector: any;
declare const i18nextHttpBackend: any;

export class I18nManager {
    langs = ['fr', 'en'];

    finalUsedLanguage = '';

    constructor() {
        if (!I18nManagerInstance) {
            I18nManagerInstance = this;
        }
        return I18nManagerInstance;
    }

    init() {
        return new Promise((i18nInitResolve, i18nInitReject) => {
            i18next
                .use(i18nextBrowserLanguageDetector)
                .use(i18nextHttpBackend)
                .init({
                    debug: true,
                    partialBundledLanguages: true,
                    languages: this.langs,
                    load: 'languageOnly',
                    fallbackLng: 'en',
                    backend: {
                        // for all available options read the backend's repository readme file
                        loadPath: '/assets/i18n/{{lng}}.json',
                    },
                    detection: {
                        order: ['navigator'],
                    },
                })
                .then(
                    () => {
                        i18next.changeLanguage(i18next.resolvedLanguage);
                        // document.querySelector('html').setAttribute('lang', i18next.resolvedLanguage);
                        i18nInitResolve(true);
                    },
                    (error: any) => {
                        i18nInitReject();
                    }
                );
        });
    }
}

export default new I18nManager();
