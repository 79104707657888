import { LINEFEST_MODE, LINEFEST_SELECTED_PROVIDER, getArtistsWithFestivals, getFestivals, getLocations, getMusicGenres, getMusicMainGenres } from './utils';

declare const dayjs: any;

let InternalDataManagerInstance: any;

export class InternalDataManager {
    public festivals: any = null;
    public locations: any = null;
    public artistsWithFestivals: any = null;

    public musicGenres = null;
    public musicMainGenres = null;

    public selectedProvider: any = '';
    public selectedMode: any = '';

    public selectedFestivalData: any;
    public selectedFestivalDates: any;
    public selectedFestivalVenues: any;

    constructor() {
        if (!InternalDataManagerInstance) {
            InternalDataManagerInstance = this;
        }
        return InternalDataManagerInstance;
    }

    init() {
        console.log('InternalDataManager init');

        return new Promise(async (internalDataManagerInitResolve, internalDataManagerInitReject) => {
            this.festivals = await getFestivals();
            this.festivals = this.festivals.filter((festival: any) => {
                const festivalStartMonth = dayjs(festival.start_datetime).format('MM');
                const festivalEndMonth = dayjs(festival.end_datetime).format('MM');
                const festivalEndDay = dayjs(festival.end_datetime).format('DD');
                const nowMonth = dayjs().format('MM');
                return (
                    ((festivalStartMonth >= nowMonth && festivalEndMonth >= nowMonth) || (festivalStartMonth <= nowMonth && festivalEndMonth >= nowMonth && festivalEndDay > dayjs().format('DD'))) &&
                    dayjs(festival.start_datetime).format('YYYY') === dayjs().format('YYYY')
                );
            });
            console.log(this.festivals);

            this.musicGenres = await getMusicGenres();
            console.log(this.musicGenres);

            this.musicMainGenres = await getMusicMainGenres();
            console.log(this.musicMainGenres);

            this.artistsWithFestivals = await getArtistsWithFestivals();
            console.log(this.artistsWithFestivals);

            this.selectedProvider = localStorage.getItem(LINEFEST_SELECTED_PROVIDER) ? localStorage.getItem(LINEFEST_SELECTED_PROVIDER) : '';
            this.selectedMode = localStorage.getItem(LINEFEST_MODE) ? localStorage.getItem(LINEFEST_MODE) : '';

            this.locations = await getLocations();
            internalDataManagerInitResolve(true);
        });
    }

    getFestivalMainInfosById(festivalId: string) {
        return this.festivals.find((festival: any) => festival.id === parseInt(festivalId, 10));
    }

    getFestivalsByCountry(countryId: string) {
        return this.festivals.filter((festival: any) => festival.city.country.id === parseInt(countryId, 10));
    }

    getFestivalsForArtist(id: string) {
        let festivals = [];
        for (let artist in this.artistsWithFestivals) {
            if (this.artistsWithFestivals[artist].id === parseInt(id, 10)) {
                festivals = this.artistsWithFestivals[artist].festivals;
            }
        }
        return festivals;
    }
}

export default new InternalDataManager();
