import {
    SPOTIFY_ACCESS_TOKEN_CLIENT_EXPIRATION_TS_LS_KEY,
    SPOTIFY_ACCESS_TOKEN_CLIENT_LS_KEY,
    SPOTIFY_ACCESS_TOKEN_EXPIRATION_TS_LS_KEY,
    SPOTIFY_ACCESS_TOKEN_LS_KEY,
    SPOTIFY_REFRESH_TOKEN_LS_KEY,
    SPOTIFY_VERIFIER_LS_KEY,
} from './spotify/authCodeWithPkce';
import {
    LINEFEST_ARTISTS_EXPIRATION_TS,
    LINEFEST_FESTIVALS_MATCHES,
    LINEFEST_MODE,
    LINEFEST_SELECTED_FESTIVAL,
    LINEFEST_SELECTED_FESTIVAL_EDITION,
    LINEFEST_SELECTED_FESTIVAL_EDITION_NAME,
    LINEFEST_SELECTED_PROVIDER,
    LINEFEST_USER_ARTISTS,
    hasArtistsTSExpire,
} from './utils';

let UserManagerInstance: any;

export class UserManager {
    public userFollowingArtists: any = [];
    public userGenres: any = new Set();
    public userGenresWeighted: any = {};
    public userGenresWeightedSorted: any = [];

    $logout: any = null;
    $login: any = null;

    constructor() {
        if (!UserManagerInstance) {
            UserManagerInstance = this;
        }
        return UserManagerInstance;
    }

    init() {
        return new Promise(async (userManagerInitResolve, userManagerInitReject) => {
            this.$logout = document.querySelector('.menu [data-action="logout"]');
            this.$login = document.querySelector('.menu [data-action="login"]');

            if (this.$logout) {
                this.$logout.addEventListener('click', this.logout.bind(this));
            }
            if (this.$login) {
                this.$login.addEventListener('click', this.login.bind(this));
            }

            let localArtists: any = localStorage.getItem(LINEFEST_USER_ARTISTS);
            if (localArtists) {
                localArtists = JSON.parse(localArtists);
            }

            let localArtistsSYTS: any = localStorage.getItem(LINEFEST_ARTISTS_EXPIRATION_TS);

            // test timestamp localArtists
            let fetchArtists = true;
            if (localArtists && localArtistsSYTS) {
                fetchArtists = hasArtistsTSExpire();
            }

            if (this.userFollowingArtists.length === 0 && !fetchArtists) {
                this.userFollowingArtists = Object.values(localArtists);
                console.log(this.userFollowingArtists);
                this.updateGenres();
            }
            userManagerInitResolve(true);
        });
    }

    async logout() {
        localStorage.removeItem(SPOTIFY_ACCESS_TOKEN_LS_KEY);
        localStorage.removeItem(SPOTIFY_ACCESS_TOKEN_CLIENT_LS_KEY);
        localStorage.removeItem(SPOTIFY_REFRESH_TOKEN_LS_KEY);
        localStorage.removeItem(SPOTIFY_VERIFIER_LS_KEY);
        localStorage.removeItem(SPOTIFY_ACCESS_TOKEN_EXPIRATION_TS_LS_KEY);
        localStorage.removeItem(SPOTIFY_ACCESS_TOKEN_CLIENT_EXPIRATION_TS_LS_KEY);

        localStorage.removeItem(LINEFEST_SELECTED_FESTIVAL_EDITION);
        localStorage.removeItem(LINEFEST_SELECTED_FESTIVAL_EDITION_NAME);
        localStorage.removeItem(LINEFEST_SELECTED_FESTIVAL);
        localStorage.removeItem(LINEFEST_SELECTED_PROVIDER);
        localStorage.removeItem(LINEFEST_MODE);
        localStorage.removeItem(LINEFEST_USER_ARTISTS);
        localStorage.removeItem(LINEFEST_FESTIVALS_MATCHES);
        localStorage.removeItem(LINEFEST_ARTISTS_EXPIRATION_TS);

        const $menu = document.querySelector('.nav-menu.menu');
        if ($menu) {
            $menu.classList.remove('active');
        }
        const $menuBtn = document.querySelector('.nav-btn');
        if ($menuBtn) {
            $menuBtn.classList.remove('active');
        }
        document.querySelector('body')?.classList.remove('no-scroll');

        this.showLoginButton();
        this.hideLogoutButton();

        try {
            if (window['supabase']) {
                const { error } = await window['supabase'].auth.signOut();
                if (error) {
                    console.log(error);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    hideLoginButton() {
        this.$login.style.display = 'none';
    }

    showLogoutButton() {
        this.$logout.style.display = 'flex';
    }

    showLoginButton() {
        this.$login.style.display = 'flex';
    }

    hideLogoutButton() {
        this.$logout.style.display = 'none';
    }

    async login() {
        const $menu = document.querySelector('.nav-menu.menu');
        if ($menu) {
            $menu.classList.remove('active');
        }
        const $menuBtn = document.querySelector('.nav-btn');
        if ($menuBtn) {
            $menuBtn.classList.remove('active');
        }
        document.querySelector('body')?.classList.remove('no-scroll');
        const $festivals = document.getElementById('festivals');
        if ($festivals) {
            scroll({
                top: $festivals.offsetTop,
                behavior: 'smooth',
            });
        }
    }

    storeUserArtists(artistsNames: string[], artistsSFInfos: any) {
        let userArtists: any = {};
        artistsNames.forEach((artistsName, index) => {
            userArtists[artistsName] = artistsSFInfos[index];
        });
        localStorage.setItem(LINEFEST_USER_ARTISTS, JSON.stringify(userArtists));
        let expiration: Date = new Date();
        expiration.setSeconds(new Date().getSeconds() + 86400); // 1 day
        localStorage.setItem(LINEFEST_ARTISTS_EXPIRATION_TS, String(expiration.getTime()));
    }

    storeUserFestivalsMatches(festivalMatches: any) {
        let lightFestivalMatches: any = [];
        festivalMatches.forEach((festivalMatch) => {
            lightFestivalMatches.push({
                id: festivalMatch.id,
                matchingScores: festivalMatch.matchingScores,
            });
        });
        localStorage.setItem(LINEFEST_FESTIVALS_MATCHES, JSON.stringify(lightFestivalMatches));
    }

    getUserFestivalsMatches() {
        return localStorage.getItem(LINEFEST_FESTIVALS_MATCHES);
    }

    updateGenres() {
        this.userFollowingArtists.forEach((followingArtist: any) => {
            if (followingArtist.genres.length > 0) {
                followingArtist.genres.forEach((genre: any) => {
                    this.userGenres.add(genre);

                    if (this.userGenresWeighted[genre]) {
                        this.userGenresWeighted[genre].count += 1;
                        this.userGenresWeighted[genre].artists.push({
                            artistId: followingArtist.id,
                            matchingRank: followingArtist.matchingRank,
                        });
                    } else {
                        this.userGenresWeighted[genre] = {
                            label: genre,
                            artists: [
                                {
                                    artistId: followingArtist.id,
                                    matchingRank: followingArtist.matchingRank,
                                },
                            ],
                            count: 1,
                        };
                    }
                });
            }
        });

        // console.log(this.userGenresWeighted);

        // sort userGenresByWeight
        this.userGenresWeightedSorted = Object.entries(this.userGenresWeighted)
            .sort((a: any, b: any) => {
                return b[1].count - a[1].count;
            })
            .map((e) => e[1]);
        // console.log(this.userGenresWeightedSorted);
    }

    getUserLinkedArtistsByGenre(genre: string) {
        // Top Three
        const genreWeighted = this.userGenresWeighted[genre];
        if (genreWeighted) {
            let artists = genreWeighted.artists;
            if (artists.length > 0) {
                artists = artists.map((artist) => {
                    let rawArtist = {};
                    this.userFollowingArtists.forEach((userFollowingArtist) => {
                        if (userFollowingArtist.id === artist.artistId) {
                            rawArtist = userFollowingArtist;
                        }
                    });
                    return rawArtist;
                });
                artists = artists.sort(() => Math.random() - 0.5); //.sort((artistA, artistB) => artistB.popularity - artistA.popularity);
                artists = artists.slice(0, 3);
            }
            return artists;
        }
    }
}

export default new UserManager();
