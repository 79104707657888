import { spotifyClientId, spotifyClientSecret } from '.';
import { LineFestError } from '../utils';

export const SPOTIFY_REFRESH_TOKEN_LS_KEY = 'linefest_spotify_refresh_token';
export const SPOTIFY_ACCESS_TOKEN_LS_KEY = 'linefest_spotify_access_token';
export const SPOTIFY_ACCESS_TOKEN_CLIENT_LS_KEY = 'linefest_spotify_client_access_token';
export const SPOTIFY_ACCESS_TOKEN_EXPIRATION_TS_LS_KEY = 'linefest_spotify_access_token_expiration_timestamp';
export const SPOTIFY_ACCESS_TOKEN_CLIENT_EXPIRATION_TS_LS_KEY = 'linefest_spotify_client_access_token_expiration_timestamp';
export const SPOTIFY_VERIFIER_LS_KEY = 'linefest_spotify_verifier';

declare const Sentry: any;

export async function redirectToSpotifyAuthCodeFlow(clientId: string) {
    const verifier = generateCodeVerifier(128);
    const challenge = await generateCodeChallenge(verifier);

    localStorage.setItem(SPOTIFY_VERIFIER_LS_KEY, verifier);

    const params = new URLSearchParams();
    params.append('client_id', clientId);
    params.append('response_type', 'code');
    params.append('redirect_uri', `${import.meta.env.VITE_REDIRECT_URI}/callback`);
    params.append('scope', 'user-follow-read user-library-read');
    params.append('code_challenge_method', 'S256');
    params.append('code_challenge', challenge);

    document.location = `https://accounts.spotify.com/authorize?${params.toString()}`;
}

export async function getSpotifyClientAccessToken() {
    const params = new URLSearchParams();
    params.append('grant_type', 'client_credentials');

    const result = await fetch('https://accounts.spotify.com/api/token', {
        method: 'POST',
        body: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Basic ' + btoa(spotifyClientId + ':' + spotifyClientSecret),
        },
    });

    try {
        if (result.status === 200) {
            const { access_token, expires_in } = await result.json();
            localStorage.setItem(SPOTIFY_ACCESS_TOKEN_CLIENT_LS_KEY, access_token);
            var tokenexpiration: Date = new Date();
            tokenexpiration.setSeconds(new Date().getSeconds() + parseInt(expires_in));
            localStorage.setItem(SPOTIFY_ACCESS_TOKEN_CLIENT_EXPIRATION_TS_LS_KEY, String(tokenexpiration.getTime()));
            return access_token;
        } else {
            if (result.body) {
                const message: any = await result.json();
                throw new LineFestError(message.error_description);
            }
        }
    } catch (error: any) {
        throw new LineFestError(error);
    }
}

export async function getSpotifyAccessToken(clientId: string, code: string) {
    const verifier = localStorage.getItem(SPOTIFY_VERIFIER_LS_KEY);

    const params = new URLSearchParams();
    params.append('client_id', clientId);
    params.append('grant_type', 'authorization_code');
    params.append('code', code);
    params.append('redirect_uri', `${import.meta.env.VITE_REDIRECT_URI}/callback`);
    params.append('code_verifier', verifier!);

    const result = await fetch('https://accounts.spotify.com/api/token', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: params,
    });

    try {
        if (result.status === 200) {
            const { access_token, refresh_token, expires_in } = await result.json();
            localStorage.setItem(SPOTIFY_REFRESH_TOKEN_LS_KEY, refresh_token);
            localStorage.setItem(SPOTIFY_ACCESS_TOKEN_LS_KEY, access_token);
            var tokenexpiration: Date = new Date();
            tokenexpiration.setSeconds(new Date().getSeconds() + parseInt(expires_in));
            localStorage.setItem(SPOTIFY_ACCESS_TOKEN_EXPIRATION_TS_LS_KEY, String(tokenexpiration.getTime()));
            return access_token;
        } else {
            if (result.body) {
                const message: any = await result.json();
                throw new LineFestError(message.error_description);
            }
        }
    } catch (error: any) {
        throw new LineFestError(error);
    }
}

export const hasSpotifyAccessTokenExpire = () => {
    const access_token_expiration_timestamp = Number(localStorage.getItem(SPOTIFY_ACCESS_TOKEN_EXPIRATION_TS_LS_KEY));
    const now = Date.now();
    return now > access_token_expiration_timestamp;
};

export const getSpotifyRefreshToken = async (clientId: string) => {
    // refresh token that has been previously stored
    const refreshToken = localStorage.getItem(SPOTIFY_REFRESH_TOKEN_LS_KEY);
    const url = 'https://accounts.spotify.com/api/token';

    if (refreshToken) {
        const params = new URLSearchParams();
        params.append('client_id', clientId);
        params.append('grant_type', 'refresh_token');
        params.append('refresh_token', refreshToken);

        const payload = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: params,
        };

        const result = await fetch(url, payload);
        const response = await result.json();

        localStorage.setItem(SPOTIFY_ACCESS_TOKEN_LS_KEY, response.access_token);
        localStorage.setItem(SPOTIFY_REFRESH_TOKEN_LS_KEY, response.refresh_token);
        var tokenexpiration: Date = new Date();
        tokenexpiration.setSeconds(new Date().getSeconds() + parseInt(response.expires_in));
        localStorage.setItem(SPOTIFY_ACCESS_TOKEN_EXPIRATION_TS_LS_KEY, String(tokenexpiration.getTime()));

        return response.access_token;
    }
};

function generateCodeVerifier(length: number) {
    let text = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

async function generateCodeChallenge(codeVerifier: string) {
    const data = new TextEncoder().encode(codeVerifier);
    const digest = await window.crypto.subtle.digest('SHA-256', data);
    return btoa(String.fromCharCode.apply(null, [...new Uint8Array(digest)]))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
}
