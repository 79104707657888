let MenuManagerInstance: any;

export class MenuManager {
    constructor() {
        if (!MenuManagerInstance) {
            MenuManagerInstance = this;
        }
        return MenuManagerInstance;
    }

    init() {
        return new Promise((menuInitResolve, menuInitReject) => {
            /* ---------------------- MENU --------------------- */

            const $navBtn = document.querySelector('.nav-btn');
            if ($navBtn) {
                $navBtn.addEventListener('click', () => {
                    const $navMenu = document.querySelector('.nav-menu');
                    if ($navMenu) {
                        if ($navMenu.classList.contains('active')) {
                            $navMenu.classList.remove('active');
                        } else {
                            $navMenu.classList.add('active');
                        }
                    }
                    if ($navBtn.classList.contains('active')) {
                        $navBtn.classList.remove('active');
                    } else {
                        $navBtn.classList.add('active');
                    }
                    const $body = document.querySelector('body');
                    if ($body) {
                        if ($body.classList.contains('no-scroll')) {
                            $body.classList.remove('no-scroll');
                        } else {
                            $body.classList.add('no-scroll');
                        }
                    }
                });
            }

            /*----------------- SCROLL SECTION	-----------------*/
            const $menuLinks = document.querySelectorAll('.menu a');
            if ($menuLinks.length > 0) {
                $menuLinks.forEach(($menuLink) => {
                    $menuLink.addEventListener('click', (event: any) => {
                        const $currentTarget = event.currentTarget;
                        if ($currentTarget) {
                            let targetSrc = $currentTarget.getAttribute('href');
                            if (targetSrc && targetSrc.indexOf('./') !== -1 && targetSrc.indexOf('../') === -1) {
                                targetSrc = targetSrc.replace('./', '');
                                if (targetSrc.indexOf('legal') === -1) {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    const target = document.querySelector(targetSrc);
                                    if (target) {
                                        scroll({
                                            top: target.getBoundingClientRect().top,
                                            behavior: 'smooth',
                                        });
                                        const $menu = document.querySelector('.nav-menu.menu');
                                        if ($menu) {
                                            $menu.classList.remove('active');
                                        }
                                        const $menuBtn = document.querySelector('.nav-btn');
                                        if ($menuBtn) {
                                            $menuBtn.classList.remove('active');
                                        }
                                        const $body = document.querySelector('body');
                                        if ($body) {
                                            $body.classList.remove('no-scroll');
                                        }
                                    }
                                }
                            }
                        }
                    });
                });
            }
            menuInitResolve(true);
        });
    }
}

export default new MenuManager();
