import {
    SPOTIFY_ACCESS_TOKEN_CLIENT_EXPIRATION_TS_LS_KEY,
    SPOTIFY_ACCESS_TOKEN_CLIENT_LS_KEY,
    SPOTIFY_ACCESS_TOKEN_EXPIRATION_TS_LS_KEY,
    SPOTIFY_ACCESS_TOKEN_LS_KEY,
    SPOTIFY_REFRESH_TOKEN_LS_KEY,
    SPOTIFY_VERIFIER_LS_KEY,
} from './spotify/authCodeWithPkce';

declare const Sentry: any;

export async function getFestivalData(festivalSlug: string, festivalYear: string, festivalEditionName: string): Promise<any> {
    const url =
        festivalEditionName !== ''
            ? `/assets/json/festivals/${festivalYear}/${festivalSlug}_${festivalEditionName}.json?ts=${Date.now()}`
            : `/assets/json/festivals/${festivalYear}/${festivalSlug}.json?ts=${Date.now()}`;
    const result = await fetch(url, {
        method: 'GET',
    });

    return await result.json();
}

export async function getFestivals(): Promise<any> {
    const result = await fetch(`/assets/json/festivals.json?t=${Date.now()}`, {
        method: 'GET',
    });
    return await result.json();
}

export async function getFrenchFestivals(): Promise<any> {
    const result = await fetch(`/assets/json/french-festivals.json?t=${Date.now()}`, {
        method: 'GET',
    });
    return await result.json();
}

export async function getFrenchFestivalsHeadliners(): Promise<any> {
    const result = await fetch(`/assets/json/french-festivals-headliners.json?t=${Date.now()}`, {
        method: 'GET',
    });
    return await result.json();
}

export async function getMaingenresStats(): Promise<any> {
    const result = await fetch(`/assets/json/main-genres-stats.json?t=${Date.now()}`, {
        method: 'GET',
    });
    return await result.json();
}

export async function getFrenchRegions(): Promise<any> {
    const result = await fetch(`/assets/json/french-regions.geojson.json?t=${Date.now()}`, {
        method: 'GET',
    });
    return await result.json();
}

export async function getLocations(): Promise<any> {
    const result = await fetch(`/assets/json/locations.json?t=${Date.now()}`, {
        method: 'GET',
    });
    return await result.json();
}

export async function getArtistsWithFestivals(): Promise<any> {
    const result = await fetch(`/assets/json/artists-with-festivals.json?t=${Date.now()}`, {
        method: 'GET',
    });
    return await result.json();
}

export async function getMusicGenres(): Promise<any> {
    const result = await fetch(`/assets/json/music-genres.json?t=${Date.now()}`, {
        method: 'GET',
    });
    return await result.json();
}

export async function getMusicMainGenres(): Promise<any> {
    const result = await fetch(`/assets/json/music-main-genres.json?t=${Date.now()}`, {
        method: 'GET',
    });
    return await result.json();
}

export const formatVenueName = (venue: any): string => {
    return venue.name.toLowerCase().replace(/ /g, '').replace(/'/g, '-').replace(/é/g, 'e').replace(/è/g, 'e').replace(/ê/g, 'e');
};

export const userFollowArtistOnSelectedProvider = (list: any, artistId: string) => {
    return !!list.find((userFollowingArtist: any) => userFollowingArtist.id === artistId);
};

export const chunk = (arr: any, size: number) => Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));

export const computeFestivalMusicGenresStatistics = (festivalInfos: any) => {
    console.log(festivalInfos);

    let festivalMusicGenres: any = {};

    if (festivalInfos.shows && festivalInfos.shows.length > 0) {
        festivalInfos.shows.forEach((show: any) => {
            if (show.artist) {
                let artistGenres = [];
                if (show.artist.spotifyGenres && show.artist.spotifyGenres.length > 0) {
                    artistGenres = show.artist.spotifyGenres;
                }
                if (show.artist.spotifyRelatedGenres && show.artist.spotifyRelatedGenres.length > 0) {
                    artistGenres = show.artist.spotifyRelatedGenres;
                }
                if (artistGenres.length > 0) {
                    for (let i = 0; i < artistGenres.length; i++) {
                        const artistGenre = artistGenres[i];
                        if (!festivalMusicGenres.hasOwnProperty(artistGenre.name)) {
                            festivalMusicGenres[artistGenre.name] = artistGenre;
                            festivalMusicGenres[artistGenre.name].festivalArtistsCount = 1;
                        } else {
                            festivalMusicGenres[artistGenre.name].festivalArtistsCount += 1;
                        }
                    }
                }
            }
        });
    }

    console.log(festivalMusicGenres);
};

export class LineFestError extends Error {
    constructor(message: any) {
        super(message);
        Sentry.captureException(this);
        this.name = '';
    }
}

export const PROVIDERS = {
    APPLE_MUSIC: 'applemusic',
    SPOTIFY: 'spotify',
    DEEZER: 'deezer',
    LASTFM: 'lastfm',
};

export const cleanProviderName = (rawProviderName: string): string => {
    switch (rawProviderName) {
        case 'applemusic':
            return 'Apple Music';
            break;
        case 'spotify':
            return 'Spotify';
            break;
        case 'deezer':
            return 'Deezer';
            break;
        case 'lastfm':
            return 'Last.fm';
            break;

        default:
            return '';
            break;
    }
};

export const MODES = {
    SELECT: 'select',
    SUGGEST: 'suggest',
};

export const LINEFEST_SELECTED_FESTIVAL_EDITION = 'linefest_selected_festival_edition';
export const LINEFEST_SELECTED_FESTIVAL_EDITION_NAME = 'linefest_selected_festival_edition_name';
export const LINEFEST_SELECTED_FESTIVAL = 'linefest_selected_festival';
export const LINEFEST_SELECTED_PROVIDER = 'linefest_selected_provider';
export const LINEFEST_MODE = 'linefest_mode';
export const LINEFEST_USER_ARTISTS = 'linefest_user_artists';
export const LINEFEST_FESTIVALS_MATCHES = 'linefest_festivals_matches';
export const LINEFEST_ARTISTS_EXPIRATION_TS = 'linefest_artists_expiration_ts'; // 1 day

const params = new URLSearchParams(window.location.search);
export const code = params.get('code');
export const token = params.get('token');
if (!code) {
    localStorage.removeItem(SPOTIFY_ACCESS_TOKEN_LS_KEY);
    localStorage.removeItem(SPOTIFY_ACCESS_TOKEN_CLIENT_LS_KEY);
    localStorage.removeItem(SPOTIFY_REFRESH_TOKEN_LS_KEY);
    localStorage.removeItem(SPOTIFY_VERIFIER_LS_KEY);
    localStorage.removeItem(SPOTIFY_ACCESS_TOKEN_EXPIRATION_TS_LS_KEY);
    localStorage.removeItem(SPOTIFY_ACCESS_TOKEN_CLIENT_EXPIRATION_TS_LS_KEY);
}

export const hasArtistsTSExpire = () => {
    let test = true;
    if (localStorage.getItem(LINEFEST_ARTISTS_EXPIRATION_TS)) {
        const expiration_timestamp = Number(localStorage.getItem(LINEFEST_ARTISTS_EXPIRATION_TS));
        const now = Date.now();
        test = now > expiration_timestamp;
    }
    return test;
};

export const slugify = (text: string) => {
    return text
        .toString() // Cast to string (optional)
        .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
        .toLowerCase() // Convert the string to lowercase letters
        .trim() // Remove whitespace from both sides of a string (optional)
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w\-]+/g, '') // Remove all non-word chars
        .replace(/\-\-+/g, '-'); // Replace multiple - with single -
};

const getBase64StringFromDataURL = (dataURL: any) => dataURL.replace('data:', '').replace(/^.+,/, '');

export async function imageUrlToBase64(url: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
        /*let string = '';
        const response = await fetch(url);
        const buffer = await response.arrayBuffer();
        new Uint8Array(buffer).forEach((byte) => {
            string += String.fromCharCode(byte);
        });
        return btoa(string);*/
        const response = await fetch(url);
        const blob = await response.blob();

        // Read the Blob as DataURL using the FileReader API
        const reader = new FileReader();
        reader.onloadend = () => {
            /*// Logs data:image/jpeg;base64,wL2dvYWwgbW9yZ...
            // Convert to Base64 string
            const base64 = getBase64StringFromDataURL(reader.result);
            console.log(base64);
            // Logs wL2dvYWwgbW9yZ...*/
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });
}

export const loadSVGImage = ($img: any, path: string) => {
    return new Promise((resolve, reject) => {
        $img.setAttribute('xlink:href', path);
        $img.onload = () => {
            console.log(`loaded`);
            resolve($img);
        };
        $img.onerror = (e) => {
            reject(e);
        };
    });
};
