declare const dayjs: any;

export const initDayjsLocales = () => {
    // load locale
    var nav_langs = navigator.languages || (navigator.language ? [navigator.language] : false);
    if (!nav_langs || !nav_langs.length) return;

    // load locale
    function load_locale(locale: string) {
        if (!locale) return;
        dayjs.locale(locale);
        return true;
    }

    // dayjs locales
    var dayjs_locales = ['en', 'fr'];

    // check locale
    function check_locale(locale: string) {
        if (['en', 'en-us'].includes(locale)) return true;
        if (locale === 'zn') return load_locale('zh-cn');
        if (locale === 'no') return load_locale('nb');
        if (dayjs_locales.includes(locale)) return load_locale(locale);
    }

    // loop nav_langs array
    nav_langs.some(function (lang) {
        lang = lang.toLowerCase();
        return check_locale(lang) || (lang.includes('-') && check_locale(lang.split('-')[0]));
    });
};

export const getBrowserLanguage = () => {
    let foundNavigatorLangs = [];
    let languageBrowser = '';

    if (typeof navigator !== 'undefined') {
        if (navigator.languages) {
            // chrome only; not an array, so can't use .push.apply instead of iterating
            for (let i = 0; i < navigator.languages.length; i++) {
                foundNavigatorLangs.push(navigator.languages[i]);
            }
        }
        if (navigator.language) {
            foundNavigatorLangs.push(navigator.language);
        }
    }

    if (foundNavigatorLangs.length > 0) {
        languageBrowser = foundNavigatorLangs[0];
    } else {
        languageBrowser = 'en';
    }

    return languageBrowser.substr(0, 2);
};
